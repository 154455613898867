<template>
  <div class="container">
    <h4 class="text-center my-4">Администрация</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <div class="my-4">
        <router-link to="/change-photo-pps">
          Преподаватели
        </router-link>
      </div>


      <div class="my-4" v-if="administrations.length">
        <DataTable :value="administrations" showGridlines stripedRows responsiveLayout="scroll">
          <Column header="ФИО">
            <template #body="{data}">
              {{ data.lastname }} {{ data.firstname }} {{data.middlename}}
            </template>
          </Column>
          <Column header="Роль" field="role"></Column>
          <Column header="Фото">
            <template #body="{data}">
              <div>
                <div v-if="data.beauty_photo" class="img_photo" :style="`background-image: url(https://back.uib.kz/uploads/${data.beauty_photo});`"></div>
                <div v-else>Нету фото</div>
              </div>
            </template>
          </Column>
          <Column header="Фото (ширина 225px, высота 300px)">
            <template #body="{data}">
              <div class="mt-2 row">
                <div class="col-md-7">
                  <input type="file" accept="image/png, image/jpg, image/jpeg" class="form-control"
                         @change="handleFileUpload($event, data.user_id)">
                </div>
                <div class="col-md-5">
                  <Button icon="pi pi-save" label="Сохранить" @click="save"/>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>

      </div>

    </div>


  </div>
</template>

<script>


import {mapActions, mapState} from 'vuex'


export default {
  data() {
    return {
      loading: true,
      departmentId: 0,
      photo: {}
    }
  },
  computed: {
    ...mapState('changePhoto', ['administrations']),
  },
  methods: {
    ...mapActions('changePhoto', ['GET_ADMINISTRATIONS', 'POST_PHOTO']),
    handleFileUpload(e, user_id) {
      this.photo['user_id'] = user_id
      this.photo['file'] = e.target.files[0]
    },
    async save() {
      const res = await this.POST_PHOTO(this.photo)
      if (res) {
        this.$message({title: 'Сохранено'})
        await this.GET_ADMINISTRATIONS()
      }
    }
  },
  async mounted() {
    await this.GET_ADMINISTRATIONS()
    this.loading = false
  },
}

</script>

<style scoped>
.img_photo{
  width: 225px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
